@import 'libs';
@import "helpers/normalize";
@import 'helpers/mixins';
@import "fonts";
@import "sprite";

* {
	box-sizing: border-box;
	outline: none;
}

body, html {
	font-family: $bloggerSans;
	font-weight: 700;
	-webkit-font-smoothing: antialiased;
	position: relative;
}
.container {
	max-width: 1194px;
	width: 100%;
	margin: 0 auto;
}
body, html,h1,h2,h3,h4,h5,h6,ul,ol,li,p {
	margin: 0;
	padding: 0;
}
ul, ol, li {
	list-style: none;
}
.title {
	display: block;
	text-align: center;

	&--principal {
		font-size: 72px;
		line-height: 80px;
		font-weight: 600;
	}
 
	&--second {
		font-size: 56px;
		line-height: 64px;
		font-weight: 700;
		margin-bottom: 24px;
	}
}
.section {
	padding: 96px 0;
}
.descriptor {
	font-size: 24px;
	line-height: 32px;
	max-width: 790px;
	margin: 0 auto;
	font-weight: 400;
	text-align: center;
	margin-bottom: 79px;
}
.nowrap-text {
	display: block;
}

.button {
	font-size: 18px;
	line-height: 24px;
	text-decoration: none;
	display: inline-block;
	cursor: pointer;
	border-radius: 28px;
	transition: all .3s ease;
	font-weight: 400;
	border: none;

	&[disabled="disabled"] {
		opacity: .6;
	}

	&--default {
		padding: 17px 32px 15px;
		font-weight: 500;
	}
 
	&--thin {
		padding: 13px 24px 11px;
	}

	&--circle {
		border-radius: 50%;
		width: 48px; height: 48px;
		position: relative;
		
		&__text {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&--lang-switcher {
		position: relative;
		cursor: auto;
		text-transform: lowercase; 
	}
 
	&--play {
		border-radius: 50%;
		height: 88px;
		width: 88px;
		position: relative;
		transition: all .3s ease;
		&:hover {
			opacity: .7;
		}
		&:before {
			content: '';
			position: absolute;
			left: 55%;
			top: 50%;
			transform: translate(-50%, -50%);
			
			@extend .svg-39;
			@extend .svg-39-dims;
		}
	}
}

// Не допускается раскширение внутри медиазапросов. Поэтому вынес данные для extend в mixin.
// @extend .svg-40;
// @extend .svg-40-dims;

@mixin small-button-play() {
	background: url("../img/sprites/sprites.svg") no-repeat;
	background-position: 100% 13.367174280879865%;
	width: 18px;
	height: 22px;
}

.switch-lang {
	&-wrapper {
		position: relative;
	}
 
	&--open {
		+ .lang-switcher {
			display: block;
			top: 100%;
			opacity: 1;
			
			&--above {
				top: auto;
				bottom: 100%;
			}
		}
	}
}

.wow {
	visibility: hidden;
}

.lang-switcher {
	position: absolute;
	width: 115px;
	padding: 14px 0 17px 17px;
	text-align: left;
	border-radius: 8px;
	z-index: 0;
	top: 80%;
	left: 0;
	transform: translate(0,16px);
	display: none;
	transition: all .3s ease-in-out;

	&:before {
		content: '';
		position: absolute;
		width: 10px; height: 10px;
		transform: rotate(45deg);
		top: -5px; left: 19px;
		z-index: -1;
	}

	&--above {
		top: auto;
		bottom: 90%;
		transform: translate(0, -16px);

		&:before {
			top: auto;
			bottom: -5px;
		}
	}

	&__lang {
		display: inline-block;
		margin-bottom: 9px;

		&:last-child {
			margin-bottom: 0;
		}
	}
	&__link {
		display: inline-block;
		text-decoration: none;
		font-size: 18px;
		line-height: 24px;
		font-weight: 400;
		transition: all .3s ease;
	}
}


.link {
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	transition: all .3s ease;

	&:hover {
		opacity: .7;
	}
}

.sticky-footer {
	display: flex;
	flex-direction: column;

	main {
		flex: 1 0 auto;
	  }
	  footer {
		flex-shrink: 0;
	  }
}
@import 'helpers/sprite';
@import 'blocks/loaders';
// Header
@import 'blocks/header';
@import 'blocks/businessSimulator';
@import 'blocks/era';
@import 'blocks/news';
@import 'blocks/createSite';
@import 'blocks/weStarted';
@import 'blocks/competiiton';
@import 'blocks/assembleTeam';
@import 'blocks/breakIn';
@import 'blocks/feedback';
// Footer
@import 'blocks/footer'; 
@import 'blocks/popups'; 

@import 'blocks/media';
@import 'slick'; 