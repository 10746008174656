.feedback-page {
	padding-top: 180px;

	&__title {
		font-size: 40px;
		line-height: 1;
		font-weight: 600;
		text-align: left; 
		margin-bottom: 35px;
	}
}

.form {
	display: flex;
	flex-flow: column nowrap;
	max-width: 585px;
	width: 100%;
	margin-bottom: 81px;

	&__text {
		font-size: 18px;
		line-height: 24px;
		font-weight: 400;
		margin-bottom: 12px;
	}

	&__label {
		display: flex;
		flex-direction: column;

		&:not(:last-child) {
			margin-bottom: 32px;
		}
	}

	&__button {
		align-self: flex-start;
		padding: 17px 27px 15px;
	}

	&__field {
		appearance: none;
		padding: 16px;
		border-radius: 12px;
		font-size: 16px;
		line-height: 24px;
		font-weight: 500;
		border: none;
		
		&::placeholder {
			font-size: 16px;
			line-height: 24px;
			font-weight: 500;
			border: none;
		}
		&--text {
			resize: none;
			max-height: 168px;
			margin-bottom: 8px;
		}
	}
}