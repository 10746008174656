@import "helpers/font";

// Example: @include font-face(Samplinoff, fonts/Samplinoff);

@include font-face("Blogger Sans", "../fonts/Blogger Sans/BloggerSans-Bold", 700);
@include font-face("Blogger Sans", "../fonts/Blogger Sans/8878", 500);
@include font-face("Blogger Sans", "../fonts/Blogger Sans/BloggerSans-Regular", 400);

$system: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$bloggerSans: "Blogger Sans";