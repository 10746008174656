.breakIn {
	padding: 96px 0;

	&__banner {
		margin: 0 auto;
		overflow: hidden;
	}

	&__title {
		margin-bottom: 67px;
	}
}