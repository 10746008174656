.record-table {
	display: block;
	width: 100%;
	max-width: 990px;
	margin: 0 auto;
	padding: 16px 0 21px;
	position: relative;

	&__trophy {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 232px; height: 282px;
		z-index: 50;

		&-image {
			width: 100%;
			height: 100%;
		} 
	}

	&__traffic,
	&__version,
	&__level {
		display: inline-block;
		padding-top: 5px;
	}

	.row {
		display: flex;
		justify-content: space-between;
		padding: 0 45px 0 23px;
		align-items: center;
		margin-bottom: 1px;
		font-size: 15px;
		line-height: 1;
		font-weight: 400;
		height: 64px;

		.record-table {
			&__right-side,
			&__left-side {
				width: 100%;
				display: flex;
				align-items: center;
				max-width: 315px;
				justify-content: flex-start;
			}

			&__right-side {
				max-width: 300px;
			}

			&__place {
				position: relative;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				text-align: center;

				&-text {
					line-height: 34px;
				}
			}
		}

		&-1 {
			border-radius: 14px 14px 0 0;
			height: 50px;

			.column {
				&-3 {

					.naming {

						&__text {
							display: inline-block;
							padding-top: 5px;
						}
					}
				}
			}
		}

		&-2 {
			border-radious: none;
		}

		&-4 { 
			border-radius: 0 0 14px 14px;
		}

		.column {
			&-1 {
				width: 30px;
			}
			
			&-2 {
				width: 115px;
				margin-left: 48px;
			}

			&-3 {
				margin-left: 77px;
			}

			&-4 {
				margin-right: 58px;
				width: 42px;
			}

			&-5 {
				margin-right: 54px;
				width: 99px;
			}
		}

		.naming {
			display: flex;
			align-items: center;

			&__text {
				padding-top: 5px;
			}
			&__icon {
				margin-right: 20px;

				&--video {
					@extend .svg-37;
					@extend .svg-37-dims;
				}

				&--tribune {
					@extend .svg-38;
					@extend .svg-38-dims;
				}

				&--art {
					@extend .svg-29;
					@extend .svg-29-dims;
				}

				&-person {
					margin-right: 13px;

					&--1 {
						@extend .svg-30;
						@extend .svg-30-dims;
					}

					&--2 {
						@extend .svg-32;
						@extend .svg-32-dims;
					}

					&--3 {
						@extend .svg-33;
						@extend .svg-33-dims;
					}
				}
			}
		}
	}
}

.competition {
	&__descriptor {
		margin-bottom: 57px;
	}
}