.assembleTeam {
	&__team {
		width: 100%;
		margin: 0 auto;
		max-width: 836px;
		height: 0;
		padding-top:  520px; //43.56%;
	}

	&__descriptor {
		margin-bottom: 0;
		margin-bottom: 60px;
	}
}

.team {
	position: relative;

	&__person {
		position: absolute;
		bottom: 0;

		&-image {
			width: 100%;
		}

		&--1 {
			left: 0.8%;
			bottom: -2%;
			width: 19%;
			z-index: 10;
		}

		&--2 {
			bottom: 0%;
			left: 17.8%;
			width: 18.7%;
			z-index: 8;
		}

		&--3 {
			bottom: 0.6%;
			left: 40.6%;
			width: 15.1%;
			z-index: 6;
		}

		&--4 {
			left: auto;
			right: 29.2%;
			width: 15.8%;
			z-index: 10;
		}

		&--5 {
			bottom: 1%;
			right: 17%;
			left: auto;
			width: 17.8%;
			z-index: 8;
		}

		&--6 {
			right: -0.8%;
			left: auto;
			width: 16%;
			height: auto;
			z-index: 6;
		}

		&--7 {
			left: 0;
			bottom: 10%;
			z-index: 10;
			width: 17.1%;
		}

		&--8 {
			left: 11.4%;
			width: 20.7%;
			bottom: 8.9%;
		}

		&--9 {
			left: auto;
			right: 9%;
			bottom: 16.1%;
			width: 26.7%;
		}

		&--10 {
			left: auto;
			right: 0;
			bottom: 10.1%;
			width: 12.8%;
		}
	}
}