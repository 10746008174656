.banner {
	max-width: 990px;
	width: 100%;
	min-height: 264px;
	border-radius: 10px;
	position: relative;

	&__title {
		font-size: 40px;
		font-weight: 500;
		line-height: 1;
		position: absolute;
		left: 13.727272%;
		top: 68px;
		max-width: 255px;
		color: #fff;
	}

	&__table {
		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0; 
			max-width: 72.72%;
			width: 100%;
			top: auto;
			left: 50%;
			transform: translate(-50%, 0);
			background: #293E51;
		}

		&:before {
			opacity: .3;

			top: auto;
			bottom: 30px;
			border-radius: 2px 2px 0 0;
			height: 33px;
		}

		&:after {
			bottom: 25px;
			width: 100%;
			border-radius: 0 0 5px 5px;
			height: 5px;
			background:#293E51;
			opacity: .4;
		}
	}

	&__stuff {
		&-item {
			position: absolute;
		}
		&--1 {
			.banner__stuff {
				&-1 {
					@extend .svg-5;
					@extend .svg-5-dims;
					top: 72.2%;
					left: 24.5%;
				}
				&-2 {
					@extend .svg-6;
					@extend .svg-6-dims;
					left: 35.2%;
					top: 71.3%;
				}
				&-3 {
					@extend .svg-7;
					@extend .svg-7-dims;
					left: 41.6%;
					top: 64%;
				}
			}
		}
		&--2 {
			.banner__stuff {
				&-item {
					z-index: 4;
				}
				&-1 {
					@extend .svg-1;
					@extend .svg-1-dims;
					left: 22%;
					top: 77%;
				}
				&-2 {
					@extend .svg-2;
					@extend .svg-2-dims;
					left: 30%;
    				top: 71%;
				}
				&-3 {
					
					@extend .svg-3;
					@extend .svg-3-dims;
					left: 37.2%;
    				top: 56.9%;
				}
				&-4 {
					@extend .svg-4;
					@extend .svg-4-dims;
					right: 41.6%;
					bottom: 11.5%;
				}
			}
		}
		&--3 {
			.banner__stuff {
				&-1 {
					@extend .svg-16;
					@extend .svg-16-dims;
					left: 21.3%;
    				top: 74.9%;
				}
				&-2 {
					@extend .svg-17;
					@extend .svg-17-dims;
					left: 28.8%;
					top: 74.9%;
				}
				&-3 {
					
					@extend .svg-18;
					@extend .svg-18-dims;
					left: 38.3%;
					top: 59%;
				}
				&-4 {
					@extend .svg-19;
					@extend .svg-19-dims;
					left: 43.2%;
					bottom: 11.3%;
					z-index: 4;
				}
				&-5 {
					@extend .svg-20;
					@extend .svg-20-dims;
					bottom: 11.5%;
					right: 40%;
					z-index: 2;
				}
			}
		}
		&--4 {
			.banner__stuff {
				&-1 {
					@extend .svg-11;
					@extend .svg-11-dims;
					left: 24.8%;
					top: 68%;
				} 
				&-2 {
					@extend .svg-12;
					@extend .svg-12-dims;
					left: 33.3%;
					top: 70.9%;
				}
				&-3 {
					
					@extend .svg-13;
					@extend .svg-13-dims;
					left: 39.6%;
					top: 78.4%;
				}
				&-4 {
					@extend .svg-14;
					@extend .svg-14-dims;
					bottom: 11.3%;
					left: 46.5%;
				}
				&-5 {
					@extend .svg-15;
					@extend .svg-15-dims;
					bottom: 11%;
					right: 37.8%;
				}
			}
		}
		&--5 {
			.banner__stuff {
				&-1 {
					@extend .svg-8;
					@extend .svg-8-dims;
					left: 22.4%;
					bottom: 11.3%;
				}
				&-2 {
					@extend .svg-9;
					@extend .svg-9-dims;
					left: 33.4%;
					bottom: 11.3%;
				}
				&-3 {
					@extend .svg-10;
					@extend .svg-10-dims;
					left: 43%;
					bottom: 10.6%;
				}
			}
		}
		&--6 {
			.banner {
				&__stuff {
					&-1 {
						@extend .svg-27;
						@extend .svg-27-dims;
						top: 72.3%;
						left: 20.7%;
					}
					&-2 {
						@extend .svg-21;
						@extend .svg-21-dims;
						left: 29%;
						top: 70.1%;
					}
					&-3 {
						@extend .svg-28;
						@extend .svg-28-dims;
						left: 39.3%;
						top: 54.4%;
					} 
					&-4 {
						@extend .svg-24;
						@extend .svg-24-dims;
						left: 47%;
						top: 61%;
					}
				}

			}
		}
	}

	&__worker {
		left: auto;
		top: auto;
		position: absolute;

		&--1 {
			right: 20%;
			bottom: 11%;
		}

		&--2 {
			right: 18.7%;
			bottom: 11.5%;
			z-index: 2;
		}

		&--3 {
			right: 20.2%;
    		bottom: 11.4%;
		}

		&--4 {
			right: 19.5%;
			bottom: 11.7%;
		}

		&--5 {
			right: 19%;
			bottom: 11.1%;
		}

		&--6 {
			bottom: 11.7%;
			right: 14%;
		}

		&-image {
			&--1 {
				width: 198px;
				height: 176px;
			}
			&--2 {
				width: 247px;
				height: 174px;
			}
			&--3 {
				width: 200px;
				height: 178px;
			}
			&--4 {
				width: 210px;
				height: 177px;
			}
			&--5 {
				width: 262px;
				height: 179px;
			}
		}
	}

	&__bubbles {
		position: static;
		&--1 {
			.banner {
				&__bubble {
					&-1 {
						right: 72%;
						bottom: 78%;
					}

					&-2 {
						bottom: 101%;
						right: 18.5%;
					}

					&-3 {
						right: 6.3%;
						bottom: 67%;
					}
				}
			}
		}
		&--2 {
			.banner {
				&__bubble {
					&-1 {
						bottom: 78.1%;
						left: 13.5%;
					}
					&-2 {
						bottom: 101%;
						right: 27.6%;
					}
					&-3 {
						right: 11.5%;
						bottom: 68.8%;
					}
				}
			}
		}
		&--3 {
			.banner {
				&__bubble {
					&-1 {
						bottom: 80%;
    					left: 3.9%;
					}
					&-2 {
						bottom: 101.8%;
						right: 11.5%;
					}
					&-3 {
						bottom: 70.1%;
						right: 8.7%;
					}
				}
			}
		}
		&--4 {
			.banner {
				&__bubble {
					&-1 {
						bottom: 91%;
						left: -5.3%;
					}
					&-2 {
						right: 12%;
						bottom: 102%;
					}
					&-3 {
						bottom: 72.5%;
						right: 14.6%;
					}
				}
			}
		}
		&--5 {
			.banner {
				&__bubble {
					&-1 {
						bottom: 84%;
						left: 24%;
					}
					&-2 {
						right: 25%;
						bottom: 102%;
					}
					&-3 {
						right: 16.4%;
						bottom: 66.5%;
					}
				}
			}
		}
	}

	&__bubble {
		padding: 6px 10px 8px;
		font-size: 16px; 
		line-height: 1;
		font-weight: 400;
		border-radius: 6px;
		background: #ffffff;
		position: absolute;
		color: #293E52;
		top: auto;

		&:before {
			content : '';
			position: absolute;
			top: 100%;
			
		}

		&--flow-left {  
			&:before {
				right: 10px;
				border-right: 8px solid #ffffff;
  				border-bottom: 8px solid transparent;	
			}
		}

		&--flow-right {
			&:before {
				left: 10px;
				border-top: 8px solid #ffffff;
				border-right: 8px solid transparent;
			}
		}
	}

	&--1 {
		background: #7EBE62;

		&-title {
			top: 53px;
		}
	}
	&--2 {
		background: #3EBD84;
	}
	&--3 {
		background: #42AEB4;
	}
	&--4 {
		background: #6580D6;
	}
	&--5 {
		background: #B367B4;
	}
	&--6 {
		background: #192735;

		.banner {

			&__table {
				&:before,
				&:after {
					background: #000000;
				}

				&:before {
					opacity: .3;

				}

				&:after {
					opacity: .4;
				}
			}
		}
	}

	&__light {
		@extend .svg-25;
		@extend .svg-25-dims;
		position: absolute;
		left: auto;
		top: 0;
		right: 0.1%;
		animation: lightLamp 3s  infinite;
		z-index: 20;
	}
}

.cracker {
	&__window {
		position: absolute;
		width: 138px;
		height: 132px;
		background: #2A4966;
		overflow: hidden;
		z-index: 2;
		left: 19.2%;
		top: 0;

		&:before,
		&:after {
			content: '';
			position: absolute;
			background: #1A2733;
			z-index: 12;
		}

		&:before {
			width: 100%;
			height: 6px;
			top: 31px
		}

		&:after {
			height: 100%;
			width: 6px;
			left: 50%;
			transform: translate(-50%, 0);
		}

		.helicopter {
			position: absolute;
			left: 0; top: 0; 
			&--right {
				@extend .svg-23;
				@extend .svg-23-dims;
				animation: copter 5s linear infinite;
				z-index: 10;

			}
			// &--left {
			// 	@extend .svg-22;
			// 	@extend .svg-22-dims;
			// }
		}
	}
}

.era {
	&__slider {
		overflow: hidden;
		max-width: 990px;
		width: 100%;
		margin: 0 auto;
		position: relative;
		&-wrap {
			height: 264px;
		}
	}
	&__descriptor {
		margin-bottom: 68px;
	}

	.nowrap-text {
		display: inline;
	}
}

.slider {
	[data-animation-in] {
		opacity: 0;
	}
	
	&__arrow {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translate(0, -50%);
		width: 50px;
		height: 50px;
		background: rgba(255,255,255, .2);
		cursor: pointer;
		outline: none;
		transition: all .3s ease;

		&:hover {
			background: rgba(255,255,255, .5);
		}

		&-icon {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
		&--prev {
			border-radius: 0 25px  25px 0;
			display: none;
			&-icon {
				@extend .svg-35;
				@extend .svg-35-dims;
			}

		}
		&--next {
			left: auto;
			right: 0;
			border-radius: 25px  0 0 25px;
			&-icon {
				@extend .svg-36;
				@extend .svg-36-dims;
			}
		}
	}
}

@keyframes copter {
    0% {
        top: 10%;
        left: -150%;
    }

    50% {
        top: 10%;
        left: 150%;
        transform: scale(1);
    }

    51% {
        transform: scale(-1, 1);
    }

    100% {
        transform: scale(-1, 1);
        top: 70%;
        left: -150%;
    }
}

@keyframes lightLamp {
    0% { opacity: 1; }
    7% { opacity: 1; }
    8% { opacity: .3; }
    10% { opacity: 1; }
    12% { opacity: 0; }
    14% { opacity: .6; }
    16% { opacity: .2; }
    18% { opacity: 1; }
    38% { opacity: 1; }
    39% { opacity: 0; }
    40% { opacity: 1; }
}