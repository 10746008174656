.sites {
	display: flex;
	justify-content: space-between;
	max-width: 806px;
	width: 100%;
	margin: 0 auto;

	&__item {
		text-align: center;
		width: 224px;

		&:last-child {
			margin-right: 0;
		}

		&--1 {
			.sites__layer {
				&--1 {
					background-color: #E14F36;
				}
				&--2 {
					background-color: #D24A33;
				}
			}
		}
		
		&--2 {
			.sites__layer {
				&--1 {
					background-color: #1572AA;
				}
				&--2 {
					background-color: #0D6093;
				}
			}
		}

		&--3 {
			.sites__layer {
				&--1 {
					background-color: #20A48C;
				}
				&--2 {
					background-color: #1C947E;
				}
			}
		}
	}

	&__image {
		&-wrapper {
			margin-bottom: 30px;
			position: relative;
			z-index: 0;
		}
		
		
		width: 186px;
		height: 155px;
	}

	&__text {
		font-weight: 500;
		font-size: 18px;
		line-height: 32px;
		white-space: nowrap;
	}

	&__layer {
		position: absolute;
		top: 0;
		border-radius: 4px;
		visibility: hidden;

		&--1 {
			width: 170px;
			height: 50px;
			top: -6px;
			opacity: .6;
			z-index: -1;
			left: 27px;
		}

		&--2 {
			width: 154px;
			height: 50px;
			background-color: green;
			top: -12px;
			z-index: -2;
			opacity: .3;
			left: 35px;
		}
	}
}