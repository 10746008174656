.weStarted {
	padding: 46px 0 0;
	padding-bottom: 0 !important;
	position: relative;


	&__container {
		text-align: center;
	}

	&__descriptor {
		margin-bottom: 63px;
	}

	&__button {
		margin-bottom: 56px;
	}

	&__apps {
		margin-bottom: 112px;
	}
}

.apps {
	display: flex;
	justify-content: center;

	&__image {
		width: 100%;
		height: auto;

		&-wrapper {
			width: 174px;
			height: 56px;
			
			&:not(:last-child) {
				margin-right: 31px;
			}
		}
	} 
}

.graphics {
	max-height: 278px;
	height: 19.3vw;
	min-height: 278px;
	position: relative;
	background-position: top center;
	background-size: cover;
	background-repeat: repeat-x;
	overflow: hidden;

	&__rocket, &__cloud {
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	&__rocket {
		position: absolute;
		left: 54%;
		bottom: 0; 
		transform: translate(-50%, 0);
		width: 526px;
		height: 244px;
		z-index: 1;
	}

	&__cloud {
		position: absolute;
		bottom: 0;
		z-index: 0;

		&--left {
			left: 0;
			width: 207px;
			height: 102px;
		}

		&--right {
			width: 362px;
			height: 134px;
			left: auto;
			right: 0;
		}
	}

}