%svg-common {
	background: url("../img/sprites/sprites.svg") no-repeat;
}

.svg-1 {
	@extend %svg-common;
	background-position: 93.24324324324324% 81.09965635738831%;
}

.svg-1-dims {
	width: 67px;
	height: 31px;
}

.svg-10 {
	@extend %svg-common;
	background-position: 15.024232633279484% 93.62549800796813%;
}

.svg-10-dims {
	width: 114px;
	height: 111px;
}

.svg-11 {
	@extend %svg-common;
	background-position: 93.10344827586206% 89.82142857142857%;
}

.svg-11-dims {
	width: 66px;
	height: 53px;
}

.svg-12 {
	@extend %svg-common;
	background-position: 99.28263988522238% 67.13780918727915%;
}

.svg-12-dims {
	width: 36px;
	height: 47px;
}

.svg-13 {
	@extend %svg-common;
	background-position: 99.85693848354792% 56.82967959527824%;
}

.svg-13-dims {
	width: 34px;
	height: 20px;
}

.svg-14 {
	@extend %svg-common;
	background-position: 99.25925925925925% 24.528301886792452%;
}

.svg-14-dims {
	width: 58px;
	height: 83px;
}

.svg-15 {
	@extend %svg-common;
	background-position: 0 100%;
}

.svg-15-dims {
	width: 93px;
	height: 143px;
}

.svg-16 {
	@extend %svg-common;
	background-position: 98.39416058394161% 44.54072790294627%;
}

.svg-16-dims {
	width: 48px;
	height: 36px;
}

.svg-17 {
	@extend %svg-common;
	background-position: 94.80916030534351% 52.16637781629116%;
}

.svg-17-dims {
	width: 78px;
	height: 36px;
}

.svg-18 {
	@extend %svg-common;
	background-position: 91.32352941176471% 41.57303370786517%;
}

.svg-18-dims {
	width: 53px;
	height: 79px;
}

.svg-19 {
	@extend %svg-common;
	background-position: 40.476190476190474% 72.89879931389365%;
}

.svg-19-dims {
	width: 61px;
	height: 30px;
}

.svg-2 {
	@extend %svg-common;
	background-position: 93.80664652567975% 67.13780918727915%;
}

.svg-2-dims {
	width: 71px;
	height: 47px;
}

.svg-20 {
	@extend %svg-common;
	background-position: 100% 0;
}

.svg-20-dims {
	width: 112px;
	height: 79px;
}

.svg-21 {
	@extend %svg-common;
	background-position: 97.1830985915493% 14.05693950177936%;
}

.svg-21-dims {
	width: 94px;
	height: 51px;
}

.svg-22 {
	@extend %svg-common;
	background-position: 45.033112582781456% 64.92537313432835%;
}

.svg-22-dims {
	width: 129px;
	height: 77px;
}

.svg-23 {
	@extend %svg-common;
	background-position: 75.33112582781457% 49.25373134328358%;
}

.svg-23-dims {
	width: 129px;
	height: 77px;
}

.svg-24 {
	@extend %svg-common;
	background-position: 33.38709677419355% 86.87615526802219%;
}

.svg-24-dims {
	width: 113px;
	height: 72px;
}

.svg-25 {
	@extend %svg-common;
	background-position: 0 0;
}

.svg-25-dims {
	width: 621px;
	height: 264px;
}

.svg-26 {
	@extend %svg-common;
	background-position: 0 64.86486486486487%;
}

.svg-26-dims {
	width: 272px;
	height: 206px;
}

.svg-27 {
	@extend %svg-common;
	background-position: 93.52409638554217% 75.17605633802818%;
}

.svg-27-dims {
	width: 69px;
	height: 45px;
}

.svg-28 {
	@extend %svg-common;
	background-position: 90.78947368421052% 24.952015355086374%;
}

.svg-28-dims {
	width: 49px;
	height: 92px;
}

.svg-29 {
	@extend %svg-common;
	background-position: 97.62237762237763% 60%;
}

.svg-29-dims {
	width: 18px;
	height: 18px;
}

.svg-3 {
	@extend %svg-common;
	background-position: 49.45454545454545% 49.9054820415879%;
}

.svg-3-dims {
	width: 183px;
	height: 84px;
}

.svg-30 {
	@extend %svg-common;
	background-position: 97.43223965763195% 95.53264604810997%;
}

.svg-30-dims {
	width: 32px;
	height: 31px;
}

.svg-32 {
	@extend %svg-common;
	background-position: 98.14285714285714% 86.42611683848797%;
}

.svg-32-dims {
	width: 33px;
	height: 31px;
}

.svg-33 {
	@extend %svg-common;
	background-position: 83.19088319088318% 45.438898450946645%;
}

.svg-33-dims {
	width: 31px;
	height: 32px;
}

.svg-35 {
	@extend %svg-common;
	background-position: 99.44903581267218% 19.90049751243781%;
}

.svg-35-dims {
	width: 7px;
	height: 10px;
}

.svg-36 {
	@extend %svg-common;
	background-position: 98.48484848484848% 19.90049751243781%;
}

.svg-36-dims {
	width: 7px;
	height: 10px;
}

.svg-37 {
	@extend %svg-common;
	background-position: 99.72105997210599% 17.003367003367003%;
}

.svg-37-dims {
	width: 16px;
	height: 19px;
}

.svg-38 {
	@extend %svg-common;
	background-position: 99.860529986053% 60%;
}

.svg-38-dims {
	width: 16px;
	height: 18px;
}

.svg-39 {
	@extend %svg-common;
	background-position: 98.72881355932203% 51.98618307426597%;
}

.svg-39-dims {
	width: 25px;
	height: 34px;
}

.svg-4 {
	@extend %svg-common;
	background-position: 98.70875179340028% 80.40885860306643%;
}

.svg-4-dims {
	width: 36px;
	height: 26px;
}

.svg-40 {
	@extend %svg-common;
	background-position: 100% 13.367174280879865%;
}

.svg-40-dims {
	width: 18px;
	height: 22px;
}

.svg-41 {
	@extend %svg-common;
	background-position: 77.13004484304933% 63.387978142076506%;
}

.svg-41-dims {
	width: 64px;
	height: 64px;
}

.svg-42 {
	@extend %svg-common;
	background-position: 99.28057553956835% 72.99145299145299%;
}

.svg-42-dims {
	width: 38px;
	height: 28px;
}

.svg-5 {
	@extend %svg-common;
	background-position: 94.66463414634147% 59.12280701754386%;
}

.svg-5-dims {
	width: 77px;
	height: 43px;
}

.svg-6 {
	@extend %svg-common;
	background-position: 46.989720998531574% 83.03886925795052%;
}

.svg-6-dims {
	width: 52px;
	height: 47px;
}

.svg-7 {
	@extend %svg-common;
	background-position: 64.88673139158576% 63.61974405850091%;
}

.svg-7-dims {
	width: 115px;
	height: 66px;
}

.svg-8 {
	@extend %svg-common;
	background-position: 99.85185185185185% 38.4083044982699%;
}

.svg-8-dims {
	width: 58px;
	height: 35px;
}

.svg-9 {
	@extend %svg-common;
	background-position: 92.54843517138599% 97.88732394366197%;
}

.svg-9-dims {
	width: 62px;
	height: 45px;
}

