.news {
	&__title {
		margin-bottom: 113px;
	}
	&__list {
		display: flex;
		justify-content: space-between;
		align-items: stretch;

		&-block {
			max-width: 378px;
		}
	}
	&-block {
		position: relative;
		border-radius: 12px;

		&__image {
			max-width: 90px;
			max-height: 90px;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			transform: translate(0, -50%);
		}

		&__link-container {
			display: block;
			padding: 65px 24px 32px 24px;
			// width: 100%;
			// height: 100%;
			text-decoration: none;
		}


		&__title {
			margin-bottom: 17px;
			font-size: 24px;
			font-weight: 500;
			max-width: 325px;
			line-height: 32px;
		}

		&__text {
			font-size: 18px;
			line-height: 24px;
			font-weight: 400;
		}
	}

	.nowrap-text {
		display: inline-block;
	}

	&__block {
		&--1, &--2 {
			margin-right: 30px;
		}
	}
}