.businessSimulator {
	padding-top: 253px;
	text-align: center;

	&__container {
		overflow: hidden;
	}

	&__title {
		margin-bottom: 17px;
	}

	&__button {
		margin-bottom: 65px;
	}

	&__descriptor {
		margin-bottom: 50px;
	}

	&__media {
		max-width: 1069px;
		width: 100%;
		margin: 0 auto;
		position: relative;
	}

	&__apps {
		margin-bottom: 112px;
	}
}

.video {
	iframe {
		border-radius: 16px;
		position: absolute;
		max-width: 100%;
		max-height: 103%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	&__container {
		overflow: hidden;
		z-index: 50;
		max-width: 790px;
		width: 100%;
		height: 0;
		margin: 0 auto;
		padding-top: 40.3%;
		border-radius: 16px;
		position: relative;
		cursor: pointer;

		&:hover {
			.video {
				&__button,
				&__text {
					opacity: .7;
				}
			}
		}
	}
	&__controls {
		position: absolute;
		top: 35%; left: 50%;
		transform: translate(-50%, 0);
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
	}

	&__button {
		margin-bottom: 22px;
	}
	
	&__text {
		cursor: pointer;
		font-size: 24px;
		line-height: 32px;
		font-weight: 500;
		transition: all .3s ease;
	}
}