.popup {
	position: absolute;
	left: 0; top: 0;
	width: 100%;
	height: 100%;
	z-index: 150;
	text-align: center;
	display: block;

	&__layer {
		position: absolute;
		left: 0; top: 0; bottom: 0; right: 0;
		opacity: .8;
		transition: all .3s ease;
	}
	&__container {
		position: fixed;
		left: 0; top: 50%;
		transform: translate(0, -50%);
		width: 100%;
		display: flex;
	}

	&__content {
		max-width: 444px;
		width: 100%;
		height: 100%;
		padding: 64px 0;
		border-radius: 16px;
		margin: 0 auto;
	}

	&__icon {
		width: 64px;
		height: 64px;
		position: relative;
		border-radius: 50%;
		margin: 0 auto 16px;

		&:before {
			content: '';
			position: absolute;
			left: 50%; top: 50%;
			transform: translate(-50%, -50%);
		}

		&--success {
			
			background-color: #1EBD9B;
			&:before {
				@extend .svg-42;
				@extend .svg-42-dims;
			}
		}

		&--failed {
			background-color: #D74545;
			&:before {
				@extend .svg-41;
				@extend .svg-41-dims;
			}
		}
	}

	&__text {
		font-size: 24px;
		line-height: 30px;
		font-weight: 700;
		display: block;
		margin-bottom: 40px;

		&--error {
			line-height: 32px;
			font-weight: 400;
			max-width: 350px;
			width: 100%;
			display: block;
			margin: 0 auto 40px;
			color: #D74545;
		}
	}

	&__button {
		max-width: 275px;
		width: 100%;
	}

	&--failed {

		.popup {
			&__text {
				&--descriptor {
					margin-bottom: 16px;
				}
			}
		}
	}

	&--close {
		display: none; 
	}
}

@media ( max-width: 480px ) {
	.popup {
		&__content {
			max-width: 90%;
			padding: 34px 15px;
		}
		&__text {
			font-size: 20px;

			&--error {
				margin: 0 auto 20px;
			}
		}
		&__button {
			max-width: 200px;
		}
	
	}
}