.header {
	position: fixed;
	padding-top: 41px;
	padding-bottom: 40px;
	width: 100%; 
	top: 0;
	left: 0;
	z-index: 110;
	margin: 0;
	font-size: 0;

	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
	} 
	&__logo {
		display: flex;
		align-items: center;
		&-brand {
			margin-right: 16px;
		}
		&-text {
			font-size: 24px;
			font-weight: 500;
			line-height: 1;
			text-decoration: none;
		}
	}

	&__admin {
		display: flex;
	}

	&__switch-lang {
		margin-right: 38px;
	}
}

.logo {
	width: 48px;
	height: 48px;
}

.nav {
	padding-left:70px;
	&__list {
		display: flex;
	}
	&__item {
		&:not(:last-child) {
			margin-right: 42px;
		}
	}

	&__link {
		text-decoration: none;
		font-size: 18px;
		line-height: 24px;
		font-weight: 400;
		transition: all .3s ease;

		&:hover {
			opacity: 0.7;
		}
	}
}

.mobile-menu {
	display: none;
	position: fixed;
	z-index: -1;
	top: 100px;
	bottom: 0;
	left: 0; right: 0;
	justify-content: space-between;
	align-items: center;
	flex-flow: column nowrap;
	transition: all .3s ease;

	&__admin {
		display: flex;
		justify-content: center;
		padding: 32px 0;
	}

	&__switch-lang {
		margin-right: 16px;
	}

	&--open {
		display: flex;

		&:before {
			content: '';
			position: absolute;
			width: 1000px; height: 1000px;
			top: 50%; left: 50%; transform: translate(-50%, -50%);
			background: inherit;
			z-index: -1;

		}
	}

	&__list {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		flex: 2;
		width: 100%;
	}

	&__item {
		&:not(:last-child) {
			margin-bottom: 47px;
		}
	}

	&__link {
		text-decoration: none;
		font-weight: 500;
		font-size: 24px;
		line-height: 1;

		
	}
}

.burger {
	display: none;
	width: 24px;
	height: 19px;
	position: relative;
	cursor: pointer;

	&--open {
		.burger__stick {
			top: 8px;

			&--1 {
				transform: rotate(45deg);
			}
			&--2 {
				display: none;
			}
			&--3 {
				transform: rotate(-45deg);
			}
		}
	}

	&__stick {
		position: absolute;
		left: 0;
		height: 3px;
		width: 100%;
		border-radius: 4px;

		&--1 {
			top: 0;
		}

		&--2 {
			top: 8px;
		}

		&--3 {
			top: auto;
			bottom: 0;
		}
	}
} 
