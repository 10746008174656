.footer {
	padding: 23px 0 40px;

	&__form {
		margin-bottom: 64px;
	}

	&__info {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
} 

.subscribe-form {
	display: flex;
	justify-content: space-between;
	align-items: center;
	&__label {
		font-size: 18px;
		line-height: 24px;
		font-weight: 500;
	}
	&__input {
		appearance: none;
		border-radius: 100px;
		border: none; 
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		padding: 17px 32px 15px;
		display: inline-block;
		margin-right: 30px;
		margin-left: auto;
		max-width: 583px;
		width: 100%;
		flex: 1;
	} 
	&__button {
		padding: 17px 40px 15px;
	}
}

.copyright {
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	display: flex;
	justify-content: flex-start;

	&__text {
		margin-right: 10px;
	}

	&__year {
		margin-right: 43px;
	}
}

.social {
	&__list {
		display: flex;
	}

	&__network {
		&:not(:last-child) {
			margin-right: 32px;
		}
	}
}