@media screen and ( max-width: 1216px ) {
	.container {
		padding: 0 24px;
	}
}

@media screen and ( max-width: 1180px ) {
	.subscribe-form {
		flex-wrap: wrap;
		justify-content: flex-start;

		&__label {
			margin-bottom: 16px;
			width: 100%;
		}

		&__input {
			margin-left: 0;
		}
	}
}

@media screen and ( max-width: 1070px ) {
	.graphics {
		height: 261px;

		&__rocket {
			width: 412px;
			height: 191px;
		}
		&__cloud {
			&--left {
				width: 146px;
				height: 72px;
			}
			&--right {
				width: 247px;
				height: 91px;
			}
		}
	}

	.era {
		.nowrap-text {
			display: block;
		}
	}
}

@media screen and ( max-width: 900px ) {
	.sites {
		&__item {
			margin-right: auto;
		}
	}

	.assembleTeam {
		&__team {
			padding-top: 62.1%;// 43.56%;
		}
	}

	.banner {
		&__title {
			left: 38px;
			top: 58px;
		}

		&__table {
			&:before,
			&:after {
				max-width: 100%;
				left: 24px;
				right: 24px;
				width: auto;
				transform: none;
			}
		}

		&__stuff {
			&--1 {
				.banner__stuff {
					&-1 {
						left: 14.9%;
					}
					&-2 {
						left: 29.8%;
					}
					&-3 {
						left: 38.5%;
					}
				}
			}
			&--2, &--3 {
				.banner__stuff {
					&-1 {
						left: 7%;
					}
					&-2 {
						left: 20%;
					}
					&-3 {
						left: 32%;
					}
				}
			}
			&--3 {
				.banner__stuff {
					&-4 {
						left: 40%;
					}
				}
			}
			&--4 {
				.banner__stuff {
					&-1 {
						left: 7.8%;
					}

					&-2 {
						left: 24.3%;
					}

					&-3 {
						left: 32.6%;
					}

					&-4 {
						left: 40.5%;
					}
				}
			}
			&--5 {
				.banner__stuff {
					&-1 {
						left: 9.4%;
					}
					&-2 {
						left: 24.3%;
					}
					&-3 {
						left: 36%;
					}
				}
			}
			&--6 {
				.banner__stuff {
					&-1 {
						left: 6.7%;
					}
					&-2 {
						left: 18%;
					}
					&-3 {
						left: 33.3%;
					}
					&-4 {
						left: 43.4%;
					}
				}
			}
		}

		&__worker {
				right: 8.6%;

				&--6 {
					right: 0.6%;
				}
		}

		&__light {
			right: -18.1%;
		}
	}

	.cracker {
		&__window {
			left: 7.2%;
		}
	}
}

@media screen and ( max-width: 860px ) {
	.footer {
		&__info {
			align-items: flex-start; 
		}
	}
	.copyright {
		flex-wrap: wrap;

		&__feedback {
			display: block;
			width: 100%;
		}
	}

	.news {
		&__block {
				margin-right: 0;

			&--1 {
				margin-right: 30px;
			}
			
			&--3 {
				display: none;
			}
		}
	}

	.nav {
		padding-left: 0;
	}

	.title {
		&--principal {
			font-size: 64px;
		}
	}

	.weStarted,
	.businessSimulator {
		&__apps {
			margin-bottom: 178px;
		}
	}

	.video {
		&__controls {
			top: 31.6%;
		}
		&__container {
			padding-top: 53.3%;
			width: 74%;
		}
		&__button {
			margin-bottom: 22px;
		}
	}

	.team {
		&__person {
			&--7, &--10 {
				bottom: 32%;
			}
			&--8, &--9 {
				bottom: 34%;
			}
		}
	}


}


@media screen and ( max-width: 768px ) {
	.section {
		padding: 76px 0;
	}

	.header {
		&__button {
			padding: 13px 26px 11px;
		}
		&__switch-lang {
			margin-right: 25px;
		}
	}

	.businessSimulator {
		padding-top: 246px;

		&__title {
			margin-bottom: 28px;
		}
	}

	.weStarted {
		padding-bottom: 0;

		&__descriptor {
			margin-bottom: 68px;
		}
	}

	.businessSimulator {
		&__apps {
			margin-bottom: 288px;
		}
	}

	.news {
		&__title {
			margin-bottom: 101px;
		}

	}
	.title {
		&--second {
			font-size: 48px;
			line-height: 56px;
		}
	}


	
	.footer {
		padding: 40px 0 28px;

		&__form {
			margin-bottom: 52px;
		}
	}

	.record-table {
		margin-bottom: 71px;

		.row {
			justify-content: space-between;
			.column {
				&-3 {
					margin-left: 35px;
				}
				&-4 {
					margin-right: 18px;
				}
				&-5 {
					margin-right: 21px;
				}
			}
			

			.record-table {
				&__right-side {
					max-width: 230px;
				}
			}
		}
	}

	.subscribe-form {
		&__input {
			margin-right: 13px;
		}
	}

	.team {
		margin-bottom: 0;
		&__person {
			&--1 {
				bottom: 5%;
				left: 3.2%;
				width: 17.6%;
			}
			&--2 {
				bottom: 5.9%;
				left: 19%;
				width: 17.6%;
			}
			&--3 {
				bottom: 7%;
				left: 40.1%;
				width: 14.1%;
			}
			&--4 {
				right: 31.6%;
				width: 14.6%;
				bottom: 7%;
			}
			&--5 {
				bottom: 6.3%;
				right: 20.2%;
				width: 16.8%;
			}
			&--6 {
				right: 3.5%;
				width: 15%;
				bottom: 6%;
			}
			&--7 {
				bottom: 0.7%;
				left: -0.2%;
				width: 25.3%;
			}
			&--8 {
				bottom: 0.7%;
				left: 14.6%;
				width: 30.7%;
			}
			&--9 {
				right: 10.3%;
				width: 39.7%;
				bottom: 16.1%;
			}
			&--10 {
				bottom: 4.7%;
				width: 19.1%;
			}
		}
	}

	.nav {
		&__item {
			&:not(:last-child) {
				margin-right: 23px;
			}
		}
	}
}

@media screen and ( max-width: 760px ) {
	
	.header {
		padding-top: 32px;
		padding-bottom: 32px;
		&__switch-lang {
			margin-right: 20px;
		}
	}

	.nav {
		&__item {
			&:not(:last-child) {
				margin-right: 26px;
			}
		}
	}

	.record-table {
		&__right-side {
			justify-content: flex-end;
		}
		.row {
			padding-right: 24px;

			.column {
				&-3 {
					margin-left: 25px;
				}
				&-4,
				&-5 {
					margin-right: 25px;
				}
			}
		}
	}
}

@media screen and ( max-width: 740px ) {
	.footer {
		&__info {
			flex-direction: column-reverse;
		}

		&__form {
			margin-bottom: 48px;
		}

		
		&__social {
			margin-bottom: 32px;
		}
	}

	.title {
		&--principal {
			font-size: 46px;
			line-height: 50px;
		}
	}

	.copyright {
		&__feedback {
			order: 1;
			margin-bottom: 32px;
		}

		&__text, &__year { order: 2; }

		&__year {
			margin-right: 0;
		}
	}

	.logo {
		width: 40px;
		height: 40px;
	}

	.businessSimulator {
		padding-top: 166px;

		&__button {
			padding: 17px 65px 15px;
		}
	}
} 

@media screen and ( max-width: 720px ) {
	.sites {
		flex-flow: column nowrap;
		max-width: 430px;
		margin: 0 auto;
		width: 100%;

		&__item {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			width: 100%;
			text-align: left;
			margin-bottom: 38px;
		}

		&__image {
			&-wrapper {
				margin: 0;
				margin-right: 19px;
			}
		}

		&__layer {
			&--1 {
				left: 10px;
			}
			&--2 {
				left: 18px;
			}
		}
	}

	.nav {
		&__list { display: none; }
	}

	.header {
		&__admin { display: none; }
	}

	.burger {
		display: block;
	}
}

@media screen and ( max-width: 620px ) {
	.era__slider-wrap,
	.banner {
		height: 316px;
	}
	.banner {

		&__title {
			font-size: 24px;
			position: relative;
			display: block;
			left: auto;
			top: auto;
			text-align: center;
			width: 100%;
			max-width: 100%;
			padding-top: 32px;
		}
		&__stuff {
			display: none;
		}

		&--6 {

			height: 264px;
		}

		&__worker {
			&--1, &--2 {
				transform: translate(50%,0);
				bottom: 9.4%;
			}
			&--1 {
				right: 45.9%;
			}

			&--2 {
				right: 49%;
			}

			&--3 {
				bottom: 9.5%;
				left: 52%;
				transform: translate(-50%, 0);
				right: auto;
			}
			&--4 {
				right: 46%;
				transform: translate(50%, 0);
				bottom: 9.4%;
			}
			&--5 {
				right: 50.3%;
				transform: translate(50%, 0);
			}
			&--6 {
				bottom: 6.7%;
				right: 50%;
				transform: translate(55%, 0);
			} 
		}

		&__table {
			&:before,
			&:after {
				max-width: 256px;
				width: 256px;
				left: 50%;
				right: auto;
				transform: translate(-50%, 0);
			}
		}

		&__light {
			background: url("../img/sprites/sprites.svg") no-repeat;
			background-position: 0 64.86486486486487%;
			width: 272px;
			height: 206px;
			left: 50%;
			transform: translate(-50%, 0);
		}
	}

	.cracker {
		&__window {
			display: none;
		}
	}

	.breakIn {
		&__title {
			margin-bottom: 17px;
		}
		&__descriptor {
			margin-bottom: 51px;
		}
	}
}

@media screen and ( max-width: 600px ) {
	.record-table {
		padding: 0;
		margin: 48px 0 46px;
		
		&__trophy {
			position: relative;
			top: auto;
			left: auto;
			transform: none;
			margin: 0 auto;
		}
		.row { display: none; }
	}
}

@media screen and ( max-width: 560px ) {
	.title {
		&--second {
			font-size: 38px;
			line-height: 41px;
		}
	}

	.descriptor {
		font-size: 18px;
		line-height: 24px;
	}

	.createSite {
		padding: 46px 0;

		&__title {
			margin-bottom: 17px;
		}

		&__descriptor {
			margin-bottom: 50px;
		}
	}

	.sites {
		max-width: 320px;

		&__descriptor {
			margin-bottom: 49px;
		}

		&__image {
			width: 100px;
			height: 84px;
		}
		&__text {
			white-space: normal;
			line-height: 24px;
		}

		&__item {
			margin-bottom: 41px;
			&--3 {
				margin-bottom: 0;
			}
		}

		&__layer {
			&--1 {
				width: 92px;
				top: -3px;
				left: 4px;
			}
			&--2 {
				width: 82px;
				top: -6px;
				left: 9px;
			}
		}
	}
	.news {
		padding: 46px 0;

		&__title {
			margin-bottom: 95px;
		}
		&__list {
			flex-flow: column nowrap;
			align-items: center;
		}
		&__block {
			margin-right: 0;
			margin-bottom: 79px;

			
			
			&--3 {
				display: block;
				margin-bottom: 0;
			}
		}

		&-block {
			&__title {
				margin-bottom: 16px;

				&--nowrap {
					display: block;
					white-space: nowrap;
				}
			}
		}
	}

	.weStarted {
		&__button {
			margin-bottom: 48px;
		}
	}

	.graphics {
		height: 160px;
		min-height: 169px;
		&__rocket {
			width: 345px;
			height: 160px;
		}

		&__cloud {
			&--right {
				transform: translate(56%, 0);
			}
		}
	}

	.competition {
		&__title {
			margin-bottom: 16px;
		}
		&__descriptor {
			margin-bottom: 51px;
		}
	}

	.video {
		&__text {
			font-size: 18px;
			line-height: 24px;
			white-space: nowrap;
		}
		&__button {
			margin-bottom: 19px;
		}
	}


	.button {
		&--play {
			height: 56px;
			width: 56px;
			&:before {
				@include small-button-play();
			}
		}
	}
}

@media screen and ( max-width: 500px ) {
	.footer {
		padding: 40px 0;
		&__form {
			margin-bottom: 48px;
		}

		&__social {
			margin-bottom: 17px;
		}
	}
	.subscribe-form {
		flex-direction: column;

		&__input {
			margin: 0;
			margin-bottom: 16px;
		}

		&__button {
			display: block;
			width: 100%;
			box-shadow: border-box;
		}

		&__label {
			margin-bottom: 24px;
		}
	}

	

	.social {
		&__list {
			flex-wrap: wrap;
		}

		&__network {
			margin-bottom: 16px;
			&:not(:last-child) {
				margin-right: 30px;
			}
		}
	}

	.record-table {
		&__trophy {
			width: 195px;
			height: 237px;
		}
	}
}

@media screen and ( max-width: 480px ) {

	.section {
		padding: 46px 0;
	}

	.record-table {
		margin-bottom: 0;
	}

	.businessSimulator {
		padding-top: 168px;

		&__title {
			margin-bottom: 15px;
		}
	}

	.team {
		&__person {
			&--1 {
				width: 19.8%;
				left: 10%;
				min-width: 72px;
			}

			&--2 {
				min-width: 72px;
				left: 23.4%;
				width: 19.7%;
			}

			&--3 {
				min-width: 57px;
				width: 15.8%;
			}

			&--4 {
				min-width: 60px;
				right: 32.3%;
				width: 16.5%;
			}

			&--5 {
				min-width: 67px;
				right: 19.7%;
				width: 18.6%;
			}

			&--6 {
				min-width: 61px;
				right: 10.2%;
				width: 16.7%;
			}

			&--7 {
				bottom: 3.5%;
				width: 18.7%;
			}
			
			&--8 {
				bottom: 8%;
				left: 16.3%;
				width: 22.7%;
			}

			&--9 {
				bottom: 16.1%;
				right: 8.3%;
				width: 29.7%;
			}

			&--10 {
				bottom: 8.7%;
				width: 14.5%;
			}
		}
	}

	.assembleTeam {
		&__title {
			margin-bottom: 16px;
		}
		&__descriptor {
			margin-bottom: 69px;
		}
	}

	.era {
		&__descriptor {
			margin-bottom: 42px;
		}
	}

	.apps {
		&__image {
			&-wrapper {
				&:not(:last-child) {
					margin-right: 17px;
				}
			}
		}
	}

	.weStarted,
	.businessSimulator {
		&__apps {
			margin-bottom: 133px;
		}
	}

	.video {
		&__controls {
			top: 24.6%;
		}
		&__container {
			width: 62.6%;
			padding-top: 45.1%;
		}
	}
	
	.feedback-page {
		&__title {
			font-size: 38px;
			line-height: 41px;
		}
	}
}

@media screen and ( max-width: 450px ) {
	.graphics {
		&__rocket {
			transform: translate(-60%, 0);
		}
	}
}

@media screen and ( max-width: 420px ) {
	.apps {
		&__image {
			&-wrapper {
				width: 45.15%;
				height: 37px;

				&:not(:last-child) {
					margin-right: 25px;
				}
			}
		}
			
	}

	.team {
		padding-top: 82%;
		&__person {
			bottom: 0;
			&--1 {
				width: 26%;
				left: -4%;
			}

			&--2 {
				left: 14.3%;
				width: 25.7%;
			}

			&--3 {
				width: 21.2%;
    			left: 36%;
			}

			&--4 {
				right: 26.3%;
				width: 22.3%;
			}

			&--5 {
				right: 9.3%;
				width: 25%;
			}

			&--6 {
				right: -3.2%;
    			width: 22%;
			}
		}
	}

	.assembleTeam {
		&__title {
			margin-bottom: 16px;
		}
	}

	.slider {
		&__arrow {
			width: 40px;
			height: 50px;
		}
	}
}
@media screen and ( max-width: 360px ) {
	.era {
		.nowrap-text {
			display: inline;
		}
	}
}
@media screen and ( max-width: 340px ) {
	.businessSimulator {
		&__button {
			padding: 17px 0 15px;
			width: 100%;
		}
	}

	.graphics {
		&__rocket {
			transform: translate(-70%, 0);
		}
	}

	.weStarted {
		&__button { width: 100%; }
	}
}

@media screen and ( max-width: 380px ) {
	.businessSimulator {
		&__apps {
			margin-bottom: 139px;
		}
	}

	.video {
		&__container {
			padding-top: 71.5%;
			width: 100%;
		}
	}

	.team {
		&__person {
			&--7 {
				bottom: 4%;
				width: 29.4%;
				left: -14%;
			}

			&--8 {
				width: 36%;
				bottom: 8%;
				left: 11.9%;
			}

			&--9 {
				width: 47.5%;
				bottom: 15%;
				right: 1.6%;
			}

			&--10 {
				width: 22.8%;
				right: -12%;
				bottom: 10%;
			}
		}
	}

	.mobile-menu {
		&__button {
			padding: 13px 44px 11px;
		}
	}
}
@media all and (max-width: 1024px) and (max-height: 414px) and (orientation:landscape) {
	.businessSimulator {
		padding-top: 125px;
	}
}

@media all and ( max-width: 812px ) and ( max-height: 414px ) and ( orientation: landscape ) {
	.header {
		padding-top: 25px;
		padding-bottom: 25px;
	}
	.mobile-menu {
		top: 90px;
		&__item {
			&:not(:last-child) {
				margin-bottom: 37px;
			}
		}
	}

	.popup {
		&__content {
			padding: 30px 0;
		}
	}
}